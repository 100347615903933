import { createApp } from 'vue';
import 'ant-design-vue/lib/style/reset.css';
import 'ant-design-vue/lib/locale/ja_JP';
import '@/styles/global.less';
import '@/styles/design-component.less';
import '@/styles/trabox-ant.less';
import '@/styles/trabox-theme.less';
import '@vant/touch-emulator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShareSquare, faBookmark } from '@fortawesome/free-regular-svg-icons';
import VantUI from '@/vant.config';
import { Locale, setNotifyDefaultOptions } from 'vant';

import jaJP from 'vant/es/locale/lang/ja-JP';
import router from './router';
import VueCookie from 'vue-cookies';

import App from './App.vue';
import { MessageAdapter, NotificationAdapter } from '@/adapter';

const app = createApp(App);

// Vue Base Plugins
app.use(router);
app.use(VueCookie);

// Ant Design Vue
import Antd from 'ant-design-vue';
app.use(Antd);

// Vant
Locale.use('ja-JP', jaJP);
new VantUI(app);
import 'vant/es/dialog/index.css';
import 'vant/es/notify/index.css';

app.config.globalProperties.$message = new MessageAdapter();
app.config.globalProperties.$notification = new NotificationAdapter();
setNotifyDefaultOptions({ duration: 5000 });

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
app.component('FontAwesomeIcon', FontAwesomeIcon);
library.add(faShareSquare);
library.add(faBookmark);

// Bootstrap App
app.mount('#app');
